<template>
    <div class="h-full relative pt-10 bg-white flex flex-col">
        <PhonePad
            :dialUssdCode="dialUssdCode"
        />

        <UssdScreen v-if="isDialingUssdCode || hasUssdMenu" />

        <div class="h-10 flex flex-row justify-center items-center border-t border-gray-300 mt-auto">
            <span
                @click="goHome"
                class="cursor-pointer text-gray-600 hover:text-gray-700 transition duration-150 ease-in-out">
                <homeSvg class="h-5 fill-current pointer-events-none" />
            </span>
        </div>
    </div>
</template>

<script>
import {
    mapGetters,
} from 'vuex';

export default {
    name: 'Calling',

    props: {
        goHome: {
            type: Function,
            default: () => () => {},
        },
    },

    computed: {
        ...mapGetters('simulator/ussd', {
            isDialingUssdCode: 'processing',
            hasUssdMenu: 'hasMenu',
        }),
    },

    methods: {
        dialUssdCode(inputText) {
            this.$store.dispatch('simulator/ussd/sendUssd', { inputText });
        },
    },

    components: {
        homeSvg: () => import('@/assets/img/home.svg'),
        PhonePad: () => import('./PhonePad.vue'),
        UssdScreen: () => import('./UssdScreen.vue'),
    },
};
</script>
